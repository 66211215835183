<template>
  <a-modal
    v-model:visible="visible"
    width="1000px"
    :footer="null"
    title="Angle Comaprison"
  >
    <div v-if="loadingImages" class="d-flex justify-content-center">
      <a-spin size="large" />
    </div>

    <div class="d-flex" v-else>
      <div>
        <a-typography-title :level="4">Original Image</a-typography-title>
        <a-image :src="controlImage" />
      </div>

      <a-divider type="vertical" />
      <div>
        <a-typography-title :level="4">Tampered Image</a-typography-title>
        <a-image :src="tamperedImage" />
      </div>
    </div>
  </a-modal>
  <a-table
    :columns="columns"
    :data-source="list"
    :row-key="(record) => record.id"
    :loading="loading"
    bordered
    :pagination="pagination"
    @change="handleTableChange"
    :scroll="{
      y: '60vh',
      x: true,
    }"
  >
    <template #bodyCell="{ column, record ,text}">
      <span v-if="column.dataIndex === 'request_status'">
        <a-tag :color="colors[text]">{{ text }}</a-tag>
      </span>
      <span v-if="column.dataIndex === 'actions'" class="d-flex flex-column">
        <div class="d-flex">
          <Actions :record="record" @change="changeState" />
          <a-button
            class="ml-auto"
            type="primary"
            @click="handleSubmit"
            :size="size"
          >
            <template #icon>
              <CheckOutlined class="d-inline-flex" />
            </template>
          </a-button>
        </div>

        <a-button
          @click="handlePreviewImage(record.id)"
          type="primary"
          class="mt-2"
          >Preview</a-button
        >
      </span>
    </template>
  </a-table>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, computed } from 'vue';
import httpClient from '../../../../service/httpClient.js';
import Actions from './Actions';
import { CheckOutlined } from '@ant-design/icons-vue';

const columns = [
  {
    title: 'Organization',
    dataIndex: 'organization',
    key: 'organization',
  },
  {
    title: 'Device',
    dataIndex: 'device',
    key: 'device',
  },
  {
    title: 'Request Status',
    dataIndex: 'request_status',
    key: 'request_status',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
  },
];

const requestStatus = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  FORWARDED: 'FORWARDED',
  RESOLVED: 'RESOLVED',
};

export default defineComponent({
  props: ['tampering_list', 'loading', 'currentPage', 'totalResult'],
  components: { Actions, CheckOutlined },
  emits: ['updateTamperingList', 'updatePage'],
  setup(props, { emit }) {
    const visible = ref(false);
    const loadingImages = ref(false);
    const images = reactive({ controlImage: null, tamperedImage: null });
    const currentFocusRecord = ref(null);
    const selectedAction = ref(null);
    const actionName = ref('none');
    const colors = {
      [requestStatus.PENDING]: 'orange',
      [requestStatus.REJECTED]: 'red',
      [requestStatus.FORWARDED]: 'blue',
      [requestStatus.RESOLVED]: 'green',
    };
    async function handlePreviewImage(id) {
      visible.value = true;

      loadingImages.value = true;

      const res = await httpClient.getData('generic/get_image_urls/' + id);

      images.controlImage = res['control_image_url'];
      images.tamperedImage = res['tampered_image_url'];

      loadingImages.value = false;

      console.log(res);
      // return res
    }

    function changeState(obj) {
      selectedAction.value = obj.actionName;
      currentFocusRecord.value = obj.id;

      console.log(obj);
    }

    function handleSubmit() {
      if (!selectedAction.value || !currentFocusRecord.value) {
        return;
      }
      if (selectedAction.value === 'Forward') {
        handleForward();
      } else if (selectedAction.value === 'Reject') {
        handleRejection();
      } else if (selectedAction.value === 'Resolve') {
        handleResolve();
      }
    }

    function updateStatusByID(id, status) {
      const updatedList = [...props.tampering_list];
      const index = updatedList.findIndex((e) => e.id === id);

      updatedList[index].request_status = status;

      emit('updateTamperingList', updatedList);
    }

    async function handleForward() {
      const res = await httpClient.patch(
        'generic/update_request_status/',
        currentFocusRecord.value,
        { request_status: requestStatus.FORWARDED }
      );
      updateStatusByID(currentFocusRecord.value, requestStatus.FORWARDED);
      console.log(res);
    }

    async function handleRejection() {
      const res = await httpClient.patch(
        'generic/update_request_status/',
        currentFocusRecord.value,
        { request_status: requestStatus.REJECTED }
      );
      updateStatusByID(currentFocusRecord.value, requestStatus.REJECTED);
      console.log(res);
    }

    async function handleResolve() {
      const res = await httpClient.patch(
        'generic/update_request_status/',
        currentFocusRecord.value,
        { request_status: requestStatus.RESOLVED }
      );
      updateStatusByID(currentFocusRecord.value, requestStatus.RESOLVED);
      console.log(res);
    }

    const pagination = computed(() => ({
      total: props.totalResult,
      current: props.currentPage,
      showSizeChanger: false,
      position: ['bottomCenter'],
    }));

    function handleTableChange(pag) {
      const { current } = pag;
      props.currentPage = current;
      emit('updatePage', current);
    }

    const list = computed(() => {
      if (actionName.value === 'none') {
        return props.tampering_list;
      }
      return props.tampering_list.filter(
        (e) => e.request_status === actionName.value
      );
    });
    return {
      columns,
      log: console.log,
      handlePreviewImage,
      visible,
      loadingImages,
      ...toRefs(images),
      changeState,
      currentFocusRecord,
      handleSubmit,
      ...toRefs(requestStatus),
      actionName,
      pagination,
      handleTableChange,
      list,
      colors,
    };
  },
});
</script>
<style scoped>
th.column-money,
td.column-money {
  text-align: right !important;
}
</style>
