<template>
  <div class="p-4">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane
        :key="status"
        :tab="status"
        v-for="status in tamperingStatuses"
      >
        <TamperingList
          :loading="loading"
          :tampering_list="tampering_list"
          :currentPage="currentPage"
          :totalResult="totalResult"
          @updateTamperingList="updateTamperingList"
          @updatePage="updatePage"
        />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import TamperingList from './TamperingList';
import httpClient from '../../../../service/httpClient.js';

export default {
  components: {
    TamperingList,
  },
  data() {
    return {
      tampering_list: [],
      activeKey: '',
      tamperingStatuses: [],
      loading: false,
      currentPage: 1,
      totalResult: 0,
    };
  },
  watch: {
    activeKey(NewValue) {
      this.getTamperingList(NewValue);
    },
  },
  created() {
    this.getTamperingStatuses();
  },
  methods: {
    handleTabChange(tabName) {
      console.log(tabName);
    },

    async getTamperingStatuses() {
      const res = await httpClient.getData('generic/get_all_statuses/');
      if (res === 'error') return;
      this.tamperingStatuses = res;
      this.activeKey = res[0];
    },
    updatePage(page = 1) {
      this.currentPage = page;
      this.getTamperingList();
    },
    async getTamperingList(statusName = this.activeKey) {
      this.loading = true;
      const url =
        'generic/get_tampering_list/?request_status=' +
        statusName +
        '&page=' +
        this.currentPage;
      const res = await httpClient.getData(url, false);
      this.tampering_list = res.data;
      this.loading = false;
      this.totalResult = res.count;
    },
    updateTamperingList(updatedTamperingList) {
      this.tampering_list = updatedTamperingList;
    },
  },
};
</script>
