<template>
  <a-select
      ref="select"
      v-model:value="actionName"
      @change="handleChange"
      class="select"
  >
    <a-select-option value="Select">Select</a-select-option>
    <a-select-option value="Forward">Forward</a-select-option>
    <a-select-option value="Resolve">Resolve</a-select-option>
    <a-select-option value="Reject">Reject</a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'Action',
  props: [
    'record'
  ],
  emits: [
    'change'
  ],
  data() {
    return {
      actionName: 'Select'
    }

  },
  methods:{
    handleChange(e){
      if(this.actionName==='Select'){
        return
      }
      this.$emit('change',{actionName:this.actionName, id:this.record.id})
    }
  }

}


</script>

<style scoped>

.select{
  flex: 1 1 auto;
}


</style>
